<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"> </el-col>
  <div class="container">
    <el-table :data="response" border style="width: 100%; height: 55vh">
      <el-table-column label="Label" prop="label"></el-table-column>
      <el-table-column label="Value" prop="value"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    response: {
      type: Array,
      required: true,
    },
  },
};
</script>
